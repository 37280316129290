import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";

const NotFoundPage = () => (
  <Layout>
    <div className="py-40 px-20">
      <h1 className="ci-h1 text-center">404</h1>
      <p className="text-center">
        Pagina non trovata :( usa il menù per navigare
      </p>
      <p className="text-center">Page not found :( use the menu to navigate</p>
    </div>
  </Layout>
);

export default NotFoundPage;
